import Cookies from 'js-cookie';

export default class CookieHelper {

    private static mapExpirationTime(timeInMins: number) : Date {
        return new Date(new Date().getTime() + timeInMins * 60 * 1000);
    }

    public static createCookie(name: string, value: string, expiryInMins: number, path: string, domain: string) {
        Cookies.set(name, value, {'expires': this.mapExpirationTime(expiryInMins), 'path': path, 'domain': domain});
    }

    public static createCookiesFromMap(attributeMap: Map<string, string>, expiryTime: number) {
        attributeMap.forEach((value, key) => {
            this.createCookie(key, value, expiryTime, '/', this.resolveDomainStringForCookie());
        })
    }

    public static readCookie(name: string) {
        return Cookies.get(name) || '';
    }

    public static resolveDomainStringForCookie() {
        let domainString = "";
        const hostName = location.hostname;
        if (hostName.indexOf('amazon.com') >= 0) {
            domainString = '.amazon.com'
        } else if (hostName.indexOf('amazon.work') >= 0) {
            domainString = '.amazon.work'
        }
        return domainString;
    }
}
