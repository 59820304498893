import React from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import {useNavigate, useSearchParams} from "react-router-dom";
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import TranslationKeys from '../../constants/translation-keys';
import { useAppSelector } from '../../redux/hooks';
import { usePrincipal } from '../../hooks';
import { useCreateAccountMutation } from '../../services/invite';
import { recordEvent } from 'src/analytics/amplify';
import ReactRoutes from "src/constants/react-routes";
import {showNewActivationFlow} from "src/constants/feature-gates";

// TODO(AtoZMobile-1851): integ /m links are broken
const ATOZ_LOGIN_URL = 'https://atoz.amazon.work/m/login';

export const CREATE_ACCOUNT_BUTTON_MUTATION = 'create-account-button-mutation';

export default function CreateAccountButton() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [principal] = usePrincipal();

    const preferredEmailIndex: number = useAppSelector(state => state.welcomeInfo.preferredEmailIndex);
    const preferredPhoneIndex: number = useAppSelector(state => state.welcomeInfo.preferredPhoneIndex);

    const [createAccount, { isLoading }] = useCreateAccountMutation({
        fixedCacheKey: CREATE_ACCOUNT_BUTTON_MUTATION,
    });

    const onContinue = () => {
        if (isLoading) {
            return;
        }

        recordEvent({
            name: 'CreateAccountButton.Click.Attempt',
            immediate: true,
        });

        if (preferredEmailIndex != 0) {
          recordEvent({
              name: 'CreateAccountButton.Click.NotPreferredEmail.Attempt',
              immediate: true,
              attributes: {
                  'emailIndex': preferredEmailIndex.toString()
              },
          });
        }

        if (preferredPhoneIndex != 0) {
          recordEvent({
              name: 'CreateAccountButton.Click.NotPreferredPhoneNumber.Attempt',
              immediate: true,
              attributes: {
                  'phoneIndex': preferredPhoneIndex.toString()
              }
          });
        }

        createAccount({
            principal: principal,
            emailIndex: preferredEmailIndex,
            phoneIndex: preferredPhoneIndex,
            locale: i18n.language,
            code: code ? code : ''
        }).unwrap()
            .then(data => {
                if (data) {
                    recordEvent({
                        name: 'CreateAccountButton.Click.Success',
                        immediate: true,
                    })
                    const { preferredUsername } = data;
                    if (preferredUsername) {
                        const url = new URL(ATOZ_LOGIN_URL);
                        url.searchParams.set('username', preferredUsername);
                        url.searchParams.set('type', 'alumni');
                        url.searchParams.set('source', 'welcome');
                        if (code) {
                            url.searchParams.set('code', code);
                        }
                        // Fix issue with deep links not opening in AtoZ app for Samsung and Firefox browsers in Android
                        if (navigator.userAgent.indexOf('Android') > 0) {
                            location.href = 'intent://' + url.host + url.pathname + url.search + '#Intent;scheme=https;package=com.amazon.atozm;end';
                        }
                        else {
                            location.href = url.toString();
                        }

                    }
                }
            })
            .catch(error => {
                recordEvent({
                    name: 'CreateAccountButton.Click.Failure',
                    immediate: true,
                })
                
                if (showNewActivationFlow() && 
                    (error as any)?.status === 401 && (error as any)?.data.message.includes('Activation code')) {
                    navigate({pathname: ReactRoutes.ALUMNI_ERROR_SCREEN, search: searchParams.toString()});
                }

                // TODO: show banner
                console.warn('failed to create user', error)
            })
    }

    return (
        <Button data-testid="continueButton" variant={ButtonVariant.Primary} style={{width : '50%'}} onClick={onContinue} disabled={isLoading}>
            {t(TranslationKeys.UI.BUTTONS.START)}
        </Button>
    );
}
