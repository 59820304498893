import { ContactInfo } from '../services/invite';
import InvalidContactInfoError from '../errors/InvalidContactInfoError';

/**
 * Verifies that conact info is not null, undefined, or phone numbers or email addresses is empty.
 * @param contactInfo contact info data from invite api
 * @throws InvalidContactInfoError if the data is invalid
 */
export function validateContactInfo(contactInfo: ContactInfo | null | undefined) {
    if (!contactInfo) {
        throw new InvalidContactInfoError('contact info is undefined or null');
    }
    if (!contactInfo.emails || !contactInfo.emails.length) {
        throw new InvalidContactInfoError('email addresses from contact info are null or empty');
    }
    if (!contactInfo.phoneNumbers || !contactInfo.phoneNumbers.length) {
      throw new InvalidContactInfoError('phone numbers from contact info are null or empty');
    }
}
