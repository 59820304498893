import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CookieHelper from 'src/helpers/cookieHelper';
import CookiesConstants from 'src/constants/cookies';
import uuid from 'react-uuid';
import Attribute from "src/constants/attribute";

const saveCookies = () => {
    const [searchParams] = useSearchParams();
    const EXPIRY_TIME_IN_MINUTES = 15;
    const attributeMap = new Map<string, string>();

    useEffect(() => {
        let sessionId = CookieHelper.readCookie(CookiesConstants.SESSION_ID);
        if (sessionId === '') {
            sessionId = uuid();
        }
        attributeMap.set(CookiesConstants.SESSION_ID, sessionId);

        const referrer = document.referrer;
        if (referrer) {
            attributeMap.set(CookiesConstants.REFERRER, referrer);
        }

        const username = searchParams.get(Attribute.USERNAME);
        if (username) {
            attributeMap.set(CookiesConstants.USERNAME, username);
        }

        const personId = searchParams.get(Attribute.PERSON_ID);
        if (personId) {
           attributeMap.set(CookiesConstants.PERSON_ID, personId);
        }

        const previousPage = CookieHelper.readCookie(CookiesConstants.CURRENT_PAGE);
        if (previousPage === '' && referrer) {
            attributeMap.set(CookiesConstants.PREVIOUS_PAGE, referrer);
        }
        else {
            attributeMap.set(CookiesConstants.PREVIOUS_PAGE, previousPage);
        }

        const currentPage = location.pathname;
        attributeMap.set(CookiesConstants.CURRENT_PAGE, currentPage);

        CookieHelper.createCookiesFromMap(attributeMap, EXPIRY_TIME_IN_MINUTES);
    }, []);
};

export default saveCookies;
