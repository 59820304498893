type contactsType = {
    [key: string]: string
}

type countryMapType = {
    [key: string]: {[key: number]: string}
}

export default class FaqErcContacts {
    public static readonly CONTACTS : contactsType = {
        US: '+1 (888) 892-7180',
        UK: '0808 145 3744',
        UK_SECOND: '020 7855 3100',
        CANADA: '1 877-467-1383',
        COSTA_RICA: '0 800 015 0702',
        CZECH_REPUBLIC: '800 701 388',
        CZECH_REPUBLIC_SECOND: '228 882 253',
        IRELAND: '1800 947784',
        IRELAND_SECOND: '165 31446',
        POLAND: '80 011 246 76',
        POLAND_SECOND: '12 881 11 61',
        SOUTH_AFRICA: '080 098 8812',
        GERMANY: '0-800-000-9781',
        GERMANY_SECOND: '(+49) 089-1208-5603',
        FRANCE: '0-800-907-578',
        FRANCE_SECOND: '+331-70-39-49-57',
        ITALY: '800 792 463',
        ITALY_SECOND: '+39 02 8295 0296',
        SLOVAKIA: '0-800-606-025',
        SLOVAKIA_SECOND: '+421-233-456-686',
        SPAIN: '900-831-779',
        SPAIN_SECOND: '+34-9-3220-3850',
        AUSTRALIA: '+61 1300 109 674',
        BAHRAIN: '80006434',
        BELGIUM: '0800-714-62',
        BELGIUM_SECOND: '+322-80-865-08',
        CHINA: '8000',
        CHINA_SECOND: '400-623-6780',
        EGYPT: '+20 22 160 4988',
        FINLAND: '0800-917-731',
        FINLAND_SECOND: '+358-9-4245-1824',
        JAPAN: '0800-123-3656',
        MEXICO: '018000623471',
        MEXICO_SECOND: '4423489568',
        MOROCCO: '+212-53-80-44-230',
        NETHERLANDS: '8000-229-880',
        NETHERLANDS_SECOND: '+31-208-085-004',
        NEW_ZEALAND: '+61 1300 109 674',
        PHILIPPINES: '+63 28 271 1438',
        PHILIPPINES_SECOND: '+63 32 402 8200',
        ROMANIA: '0-800-896-340',
        SINGAPORE: '8000',
        SINGAPORE_SECOND: '+65 64084280',
        SWEDEN: '0200-330-983',
        SWEDEN_SECOND: '+46-840-839-532',
        SWITZERLAND: '(+49) 89-2206-1049',
        UNITED_EMIRATES: '+971 45 731 573',
    };
    public static readonly PLACEHOLDER_TO_COUNTRY_MAP : countryMapType = {
        US: { 3: 'US' },
        UK: { 4: 'UK', 5: 'UK_SECOND' },
        CA: { 14: 'CANADA' },
        COSTA_RICA: { 13: 'COSTA_RICA' },
        CZECH: { 6: 'CZECH_REPUBLIC', 7: 'CZECH_REPUBLIC_SECOND' },
        IRELAND: { 8: 'IRELAND', 9: 'IRELAND_SECOND' },
        POLAND: { 10: 'POLAND', 11: 'POLAND_SECOND' },
        SOUTH_AFRICA: { 12: 'SOUTH_AFRICA' },
        INDIA: {},
        GERMANY: { 15: 'GERMANY', 16: 'GERMANY_SECOND' },
        FRANCE: { 17: 'FRANCE', 18: 'FRANCE_SECOND' },
        ITALY: { 19: 'ITALY', 20: 'ITALY_SECOND' },
        SLOVAKIA: { 21: 'SLOVAKIA', 22: 'SLOVAKIA_SECOND' },
        SPAIN: { 23: 'SPAIN', 24: 'SPAIN_SECOND' },
        AUSTRALIA: { 25: 'AUSTRALIA' },
        BAHRAIN: { 26: 'BAHRAIN' },
        BELGIUM: { 27: 'BELGIUM', 28: 'BELGIUM_SECOND' },
        CHINA: { 29: 'CHINA', 30: 'CHINA_SECOND' },
        EGYPT: { 31: 'EGYPT' },
        FINLAND: { 32: 'FINLAND', 33: 'FINLAND_SECOND' },
        JAPAN: { 34: 'JAPAN' },
        MEXICO: { 35: 'MEXICO', 36: 'MEXICO_SECOND' },
        MOROCCO: { 37: 'MOROCCO' },
        NETHERLANDS: { 38: 'NETHERLANDS', 39: 'NETHERLANDS_SECOND' },
        NEW_ZEALAND: { 40: 'NEW_ZEALAND' },
        PHILIPPINES: { 41: 'PHILIPPINES', 42: 'PHILIPPINES_SECOND' },
        ROMANIA: { 43: 'ROMANIA' },
        SINGAPORE: { 44: 'SINGAPORE', 45: 'SINGAPORE_SECOND' },
        SWEDEN: { 46: 'SWEDEN', 47: 'SWEDEN_SECOND' },
        SWITZERLAND: { 48: 'SWITZERLAND' },
        UNITED_EMIRATES: { 49: 'UNITED_EMIRATES' },
    };
}