import React, { useEffect } from 'react';
import '../.././styles/alumni.scss';
import { saveCookies } from '../../hooks';
import Footer from './Footer';
import { recordEvent } from 'src/analytics/amplify';
import CreateAccountView from "src/components/alumni/CreateAccountContentView";

function CreateAccountScreen() {
    saveCookies();
    useEffect(() => {
        recordEvent({
            name: 'CreateAccountScreen.Render'
        });
    }, []);

    return(
        <div className="fullscreen">
            <div className="activation-flow-flex-container">
                <div data-testid="createAccountBackground" className="flex-box-left-badge-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="createAccountContent" className="activation-flow-content-wrap">
                        < CreateAccountView />
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer screen="CreateAccountScreen"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAccountScreen;
