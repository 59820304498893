import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosError } from 'axios';
import { invokeInviteApi } from '../clients/awsClients';
import { Principal } from '../lib/principal';

export type ContactInfo = {
    readonly phoneNumbers: string[] | null;
    readonly emails: string[] | null;
    readonly firstName: string | null;
}

export type ContactInfoOptions = {
    readonly principal: Principal;
    readonly code: string;
}

export type CreateAccountOptions = {
    readonly principal: Principal;
    readonly emailIndex: number;
    readonly phoneIndex: number;
    readonly locale: string;
    readonly code: string;
}

export type SendInviteOptions = {
    readonly principal: Principal;
}

export type InviteConfirmation = {}

export type AccountConfirmation = {
    readonly preferredUsername: string | null;
}

const customInviteQuery = (): BaseQueryFn<
    {
      path: string
      method: 'GET' | 'POST' | 'DELETE' | 'UPDATE',
      query?: { [key: string]: string | number | boolean };
      body?: Record<string, unknown>,
    },
    unknown,
    unknown
> => async ({ path, method, query, body }) => {
    try {
        const response = await invokeInviteApi(path, method, query, body);
        return {
          data: response.data
        }
    } catch (error) {
        let err = error as AxiosError
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            }
        }
    }
}

export const inviteApi = createApi({
    reducerPath: 'inviteApi',
    baseQuery: customInviteQuery(),
    endpoints: (builder) => ({
        getContactInfo: builder.query<ContactInfo, ContactInfoOptions>({
            query: (options: ContactInfoOptions) => ({
                path: '/alumni-contact-info',
                method: 'GET',
                query: {
                    [options.principal.key]: options.principal.value,
                    'code': options.code
                }
            })
        }),
        createAccount: builder.mutation<AccountConfirmation, CreateAccountOptions>({
            query: (options: CreateAccountOptions) => ({
                path: '/alumni-account',
                method: 'POST',
                body: {
                    [options.principal.key === 'id' ? 'personId' : options.principal.key]: options.principal.value,
                    'emailIndex': options.emailIndex,
                    'phoneIndex': options.phoneIndex,
                    'locale': options.locale,
                    'code': options.code
                }
            })
        }),
        sendInvite: builder.mutation<InviteConfirmation, SendInviteOptions>({
            query: (options: SendInviteOptions) => ({
                path: '/send-invite',
                method: 'POST',
                body: {
                    [options.principal.key === 'id' ? 'personId' : options.principal.key]: options.principal.value
                }
            })
        })
    }),
})

export const { useGetContactInfoQuery, useCreateAccountMutation, useSendInviteMutation } = inviteApi;
