import React, { useEffect, useLayoutEffect } from 'react';
import { Container, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import FaqErcContacts from 'src/constants/faq-erc-contacts';
import mapValues from 'lodash.mapvalues';
import TranslationKeys from 'src/constants/translation-keys';
import { recordEvent } from 'src/analytics/amplify';
import { saveCookies } from '../../hooks';

export default function Help() {
  saveCookies();
  const { t } = useTranslation();

  useEffect(() => {
      recordEvent({
          name: 'HelpScreen.Render'
      });
  }, []);

  useLayoutEffect(() => {
    document.body.scrollTo(0, 0)
  });

  return (
      <Container>
          <View margin='24px' data-testid='faqView'>
          <Text fontSize='T300' color='#0079ad'>{t('faq_title_myhr_contact').toUpperCase()}</Text>
            <Text fontWeight='bold'>{t('faq_question_myhr_contact')}</Text>
            {Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).filter(key => ['US', 'CA', 'UK', 'JAPAN', 'MEXICO'].includes(key)).map((index?: any) => (
                <View key={`password-question-${index}`}>
                    <Text>{t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}`])}</Text>
                    <Text>{t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}_CONTACT`],
                        mapValues(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP[index],
                            countryKey => FaqErcContacts.CONTACTS[countryKey]))}</Text>
                    <br />
                </View>
            ))}
            <Text fontSize='T300' color='#0079ad'>{t('faq_title_select_mobile_phone_email').toUpperCase()}</Text>
            <Text fontWeight='bold'>{t('faq_question_select_mobile_phone_email_1')}</Text>
            <Text>{t('faq_alumni_answer_select_mobile_phone_email_1')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_select_mobile_phone_email_2')}</Text>
            <Text>{t('faq_answer_select_mobile_phone_email_2')}</Text>
            <br />
            <Text fontSize='T300' color='#0079ad'>{t('faq_title_verification_code').toUpperCase()}</Text>
            <Text fontWeight='bold'>{t('faq_alumni_question_verification_code_1')}</Text>
            <Text>{t('faq_alumni_answer_verification_code_1')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_alumni_question_verification_code_2')}</Text>
            <Text>{t('faq_alumni_answer_verification_code_2a')}</Text>
            <Text>{t('faq_alumni_answer_verification_code_2b')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_alumni_question_verification_code_3')}</Text>
            <Text>{t('faq_alumni_answer_verification_code_3')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_verification_code_4')}</Text>
            <Text>{t('faq_alumni_answer_verification_code_4')}</Text>
            <br />
            <Text fontSize='T300' color='#0079ad'>{t('faq_title_enter_login').toUpperCase()}</Text>
            <Text fontWeight='bold'>{t('faq_alumni_question_enter_login')}</Text>
            <Text>{t('faq_alumni_answer_enter_login')}</Text>
            <br />
            <Text fontSize='T300' color='#0079ad'>{t('faq_title_password').toUpperCase()}</Text>
            <Text fontWeight='bold'>{t('faq_question_password_1')}</Text>
            <Text>{t('faq_alumni_answer_password_1')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_password_3')}</Text>
            <Text>{t('faq_alumni_answer_password_3')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_password_4')}</Text>
            <Text>{t('faq_alumni_answer_password_4')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_password_5')}</Text>
            <Text>{t('faq_answer_password_5')}</Text>
            <br />
            <Text fontWeight='bold'>{t('faq_question_password_8')}</Text>
            <Text>{t('faq_alumni_answer_password_8')}</Text>
            <br />
          </View>
          <View alignItems='center' justifyContent='center'>
            <Footer screen="HelpScreen"/>
          </View>
      </Container>
  );
}
