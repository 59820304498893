import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import defaultTheme from '@amzn/stencil-react-theme-default';
import { Col, Container, GridContainer, GridItem, View } from '@amzn/stencil-react-components/layout';
import Header from '../usertypes/Header';
import Footer from '../common/Footer';
import Logo from '../common/Logo';
import AssetsLogo from 'src/images/logo.png';
import { P } from '@amzn/stencil-react-components/text';


import { useTranslation } from "react-i18next";

const PbForbiddenPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        document.title = 'Forbidden - Amazon A to Z Login'
    }, []);

    return (
        <StencilProvider
            theme={{
                ...defaultTheme,
                constants: {
                    ...defaultTheme.constants,
                    maxWidth: '768px'
                }
            }}>
            <Container className='deconsolidated-container'
                data-testid='deconsolidated-container'>
                <GridContainer>
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Header backButtonOnClick={() => navigate("/")} />
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={8} md={8} sm={4}>
                        <View flex={1} padding={{ left: 50, right: 50 }}>
                            <Container>
                                <Col>
                                    <Logo padding={{ bottom: 20 }} src={AssetsLogo} />
                                    <P>
                                        {t('forbidden_text_1')}
                                    </P>
                                    <P>
                                        {t('forbidden_text_2')}
                                    </P>
                                </Col>
                            </Container>
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Footer />
                        </View>
                    </GridItem>
                </GridContainer>
            </Container>
        </StencilProvider>
    )
}

export default PbForbiddenPage;