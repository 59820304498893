import { Principal, createPrincipal } from '../lib/principal';
import { useSearchParams } from 'react-router-dom';
import UnknownUserError from 'src/errors/UnknownUserError';

const usePrincipal: (() => [Principal]) = () => {
    const [searchParams] = useSearchParams();
    const personId = searchParams.get('id');
    const principalParam = personId ? personId : searchParams.get('username');
    if (!principalParam) {
        throw new UnknownUserError('failed to resolve principal user');
    }
    return [createPrincipal(principalParam)];
}

export default usePrincipal;
