import React, { ErrorInfo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCanGoBack } from '../../redux/error-context-slice';
import ContactHelpScreen from './ContactHelpScreen';

const mapStateToProps = (state: RootState) => ({
    canGoBack: state.errorContext.canGoBack,
});

const mapDispatch = {
    setCanGoBack: setCanGoBack
};

const connector = connect(mapStateToProps, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
interface PropsType extends PropsFromRedux {
    children: JSX.Element;
}

interface StateType {
    error: Error|null;
    hasError: boolean;
}

class ContactHelpErrorBoundary extends React.Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.props.setCanGoBack(false);
        if ((error as any)?.status === 401) {
            location.href = 'https://atoz.amazon.work/m/login'
        }
    }

    render() {
        if (this.state.hasError) {
            return (<ContactHelpScreen />);
        }
        return this.props.children;
    }
}
  
export default connector(ContactHelpErrorBoundary);
