import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RequestSourceState {
    value: string;
}

export const initialState: RequestSourceState = {
    value: '',
}

const requestSourceSlice = createSlice({
    name: 'source',
    initialState,
    reducers: {
        setRequestSource: (state: RequestSourceState, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
        clearRequestSource: (state: RequestSourceState) => {
            state.value = '';
        },
    },
});

export const { setRequestSource, clearRequestSource } = requestSourceSlice.actions;

export default requestSourceSlice.reducer;