import { useEffect } from 'react';
import { recordEvent } from 'src/analytics/amplify';
import ScreenNames from 'src/constants/screen-names';

const useRecordScreenVisit = (screenName: ScreenNames) => {
    useEffect(() => {
        recordEvent({
            name: `${screenName}.Visit.Count`,
            immediate: true
        });
    }, []);
}

export default useRecordScreenVisit;
