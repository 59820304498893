import React, { useEffect } from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import ReactRoutes from '../../constants/react-routes';
import TranslationKeys from '../../constants/translation-keys';
import '../.././styles/alumni.scss';
import AlumniLogo from '../../images/alumni_logo.svg';
import { recordEvent } from 'src/analytics/amplify';

export default function ContactHelpView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        recordEvent({
          name: 'ContactHelpView.Render',
        });
    }, []);

    const canGoBack: boolean = useAppSelector(state => state.errorContext.canGoBack);

    function handleBackButtonClick() {
        recordEvent({
            name: 'ContactHelpView.BackButton.Click',
            immediate: true
        });
        navigate({pathname: ReactRoutes.ALUMNI_CONTACT_SELECT, search: searchParams.toString()});
    }

    function handleContactUsButtonClick() {
        recordEvent({
            name: 'ContactHelpView.ContactUsButton.Click',
            immediate: true
        });
        navigate(ReactRoutes.ALUMNI_FAQ);
    }

    return (
        <View className="alumni-content">
            <Col gridGap="S300">
                <View className="logo">
                    <img src={AlumniLogo}/>
                </View>
                <View className="header">
                    <div data-testid="contactHelpHeader">
                        {t(TranslationKeys.UI.ALUMNI.HELP_HEADER)}
                    </div>
                </View>
                <View className="description">
                    <div data-testid="contactHelpBody">
                        {t(TranslationKeys.UI.ALUMNI.HELP_DESCRIPTION)}
                    </div>
                </View>
                <Row gridGap="S500">
                    {canGoBack && <Button data-testid="backButton" style={{width : '50%'}} onClick={handleBackButtonClick}>
                        {t(TranslationKeys.UI.BUTTONS.BACK)}
                    </Button>}
                    <Button
                        data-testid="getHelpButton"
                        variant={ButtonVariant.Primary}
                        style={{width : canGoBack ? '50%' : '100%'}}
                        onClick={handleContactUsButtonClick}>
                        {t(TranslationKeys.UI.BUTTONS.CONTACT_US)}
                    </Button>
                </Row>
            </Col>
        </View>
    )
}