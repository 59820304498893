const i18nConfig = require('../../configuration/i18nConfig');

export default class I18nHelper {
    
    public static getIndexOfSelectedLanguage() {
        return i18nConfig.localeArray.findIndex((element: string | null, index: number) => {
            // i18n sets an item in local storage to store the current set language
            if (element === localStorage.getItem('i18nextLng')) {
                return index;
            }
        })
        return -1;
    }

}
