import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AttributeState {
    personId: string,
    username: string,
    referrer: string,
    sessionId: string,
    pageHistory: string[],
}

export const initialState: AttributeState = {
    personId: '',
    username: '',
    referrer: '',
    sessionId: '',
    pageHistory: [],
}

const attributeSlice = createSlice({
    name: 'attribute',
    initialState,
    reducers: {
        setPersonId: (state: AttributeState, action: PayloadAction<string>) => {
            state.personId = action.payload;
        },
        setUsername: (state: AttributeState, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setReferrer: (state: AttributeState, action: PayloadAction<string>) => {
            state.referrer = action.payload;
        },
        setSessionId: (state: AttributeState, action: PayloadAction<string>) => {
            state.sessionId = action.payload;
        },
        savePageIntoHistory: (state: AttributeState, action: PayloadAction<string>) => {
            state.pageHistory = [...state.pageHistory, action.payload];
        },
    },
});

export const { setPersonId, setUsername, setReferrer, setSessionId, savePageIntoHistory } = attributeSlice.actions;

export default attributeSlice.reducer;
