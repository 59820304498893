import React, { useEffect, useLayoutEffect } from 'react';
import i18next from 'i18next';
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import AutoLinkText from 'react-autolink-text2';
import Footer from './Footer';
import { recordEvent } from 'src/analytics/amplify';
import { saveCookies } from '../../hooks';
import useLegalTranslations from './legalTranslations/useLegalTranslations';

export default function PrivacyPolicyView() {
  saveCookies();
  const { translations, setLanguage } = useLegalTranslations(i18next.language);
  useEffect(() => {
    recordEvent({
      name: 'PrivacyPolicyScreen.Render',
    });
  }, []);

  i18next.on('languageChanged', (lng) => {
    setLanguage(lng);
  });

  useLayoutEffect(() => {
    document.body.scrollTo(0, 0)
  });

  return (
    <Container>
      <View margin="24px" data-testid="privacyView">
        <Col gridGap="S400">
          <Col gridGap="S200">
            <Text fontSize="T500">
              {translations['privacy_policy_heading_1']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_1']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_2']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_2']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_3']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_3']}
            </Text>
            <ul
              style={{
                color: '#56616c',
                fontFamily: 'Amazon Ember, Helvetica, sans-serif',
                paddingLeft: 16,
              }}
            >
              {[
                translations['privacy_policy_description_3_point1'],
                translations['privacy_policy_description_3_point2'],
                translations['privacy_policy_description_3_point3'],
                translations['privacy_policy_description_3_point4'],
                translations['privacy_policy_description_3_point5'],
                translations['privacy_policy_description_3_point6'],
              ].map((point) => (
                <li>
                  <Text fontSize="T200">{point}</Text>
                </li>
              ))}
            </ul>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_3_biometrics']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_4']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_4']}
            </Text>
          </Col>
          <Col gridGap="S300">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_5']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_5']}
            </Text>
            <ul
              style={{
                color: '#56616c',
                fontFamily: 'Amazon Ember, Helvetica, sans-serif',
                paddingLeft: 16,
              }}
            >
              {[
                translations['privacy_policy_description_5_point1'],
                translations['privacy_policy_description_5_point2'],
                translations['privacy_policy_description_5_point3'],
                translations['privacy_policy_description_5_point4'],
                translations['privacy_policy_description_5_point5'],
                translations['privacy_policy_description_5_point6'],
                translations['privacy_policy_description_5_point7'],
                translations['privacy_policy_description_5_point8'],
                translations['privacy_policy_description_5_point9'],
              ].map((point) => (
                <li>
                  <Text fontSize="T200">
                    <AutoLinkText text={point} />
                  </Text>
                </li>
              ))}
            </ul>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_6']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_6_para1']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_6_para2']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_6_para3']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_6_para4']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_7']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_7']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_heading_8']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_description_8']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_1']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_1']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_2']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_2']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_3']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_3']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_4']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_4']}
            </Text>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_5']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_5_para1']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              {translations['privacy_policy_addendum_description_5_para2']}
            </Text>
            <ul
              style={{
                color: '#56616c',
                fontFamily: 'Amazon Ember, Helvetica, sans-serif',
                paddingLeft: 16,
              }}
            >
              {[
                translations['privacy_policy_addendum_description_5_point1'],
                translations['privacy_policy_addendum_description_5_point2'],
              ].map((point) => (
                <li>
                  <Text fontSize="T200" color="neutral70">
                    <AutoLinkText text={point} />
                  </Text>
                </li>
              ))}
            </ul>
          </Col>
          <Col gridGap="S200">
            <Text fontSize="T200" fontWeight="bold">
              {translations['privacy_policy_addendum_heading_6']}
            </Text>
            <Text fontSize="T200" color="neutral70">
              <AutoLinkText
                text={
                  translations['privacy_policy_addendum_description_6_para1']
                }
              />
            </Text>
            <Text fontSize="T200" color="neutral70">
              <AutoLinkText
                text={
                  translations['privacy_policy_addendum_description_6_para2']
                }
              />
            </Text>
          </Col>
        </Col>
      </View>
      <View alignItems="center" justifyContent="center">
        <Footer screen="PrivacyPolicyScreen"/>
      </View>
    </Container>
  );
}
