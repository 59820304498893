import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorContextState {
    canGoBack: boolean,
}

export const initialState: ErrorContextState = {
    canGoBack: true,
}

const errorContextSlice = createSlice({
    name: 'error-context',
    initialState,
    reducers: {
        setCanGoBack: (state: ErrorContextState, action: PayloadAction<boolean>) => {
            state.canGoBack = action.payload;
        },
    }
});

export const { setCanGoBack } = errorContextSlice.actions;

export default errorContextSlice.reducer;
