import React from "react";
import { Container, View } from "@amzn/stencil-react-components/layout";
import { H1, H2, H3, H4 } from "@amzn/stencil-react-components/text";
import { Text } from "@amzn/stencil-react-components/text";
import { useTranslation } from "react-i18next";
import TranslationKeys from "src/constants/translation-keys";
import styled from "@emotion/styled";
import FaqErcContacts from "src/constants/faq-erc-contacts";
import { showPbModal, showCsgoModal, showSruModal, isPhaseTwo, showDspaModal } from 'src/constants/feature-gates';
import mapValues from "lodash.mapvalues";

const HelpH1 = styled(Text)();
HelpH1.defaultProps = {
  ...H1.defaultProps,
  lineHeight: "44px",
  font: "primary",
  fontSize: "T500",
  fontWeight: "bold",
  color: "neutral90",
  margin: "0 0 24px 0"
};

const HelpH2 = styled(Text)();
HelpH2.defaultProps = {
  ...H2.defaultProps,
  lineHeight: "36px",
  font: "primary",
  fontSize: "T400",
  fontWeight: "bold",
  color: "neutral90",
  margin: "36px 0 18px 0"
};

const HelpH3 = styled(Text)();
HelpH3.defaultProps = {
  ...H3.defaultProps,
  lineHeight: "32px",
  font: "primary",
  fontSize: "T300",
  fontWeight: "bold",
  color: "neutral90",
  margin: "18px 0 0 0"
};

const HelpH4 = styled(Text)();
HelpH4.defaultProps = {
  ...H4.defaultProps,
  lineHeight: "32px",
  font: "primary",
  fontSize: "T200",
  fontWeight: "bold",
  color: "neutral90"
};

const HelpText = styled(Text)();
HelpText.defaultProps = {
    ...Text.defaultProps,
    lineHeight: "24px",
    font: "primary",
    fontSize: "T200",
    color: "neutral90",
}

const HelpContent = () => {
    const { t } = useTranslation();
    return (
        <Container data-testid="get-help-content-container" tabIndex={0} role="region" aria-label={t(TranslationKeys.HELP.HELP_CONTENT)}>
            <H1>
                <HelpH1 data-testid="get-help-title-header">{t(TranslationKeys.HELP.TITLE_HEADER)}</HelpH1>
            </H1>
            <H2>
                <HelpH2>{`${t(TranslationKeys.HELP.LOGIN_AMAZON_ALIAS_HEADER)}`}</HelpH2>
            </H2>
            <HelpText fontWeight="bold" margin="0 0 16px 0">
                {`${t(TranslationKeys.HELP.LOGIN_AMAZON_ALIAS_ASSOCIATE_KEY)}:`} 
                <HelpText fontWeight="regular" display="inline"> {t(TranslationKeys.HELP.LOGIN_AMAZON_ALIAS_ASSOCIATE_VALUE)}</HelpText>
            </HelpText>
            <HelpText fontWeight="bold" margin="0 0 16px 0">
                {`${t(TranslationKeys.HELP.LOGIN_AMAZON_ALIAS_SALARIED_EMPLOYEE_KEY)}:`} 
                <HelpText fontWeight="regular" display="inline"> {t(TranslationKeys.HELP.LOGIN_AMAZON_ALIAS_SALARIED_EMPLOYEE_VALUE)}</HelpText>
            </HelpText>
            <HelpText>
                {t(TranslationKeys.HELP.LOGIN_AMAZON_SUSPENDED_EMPLOYEES)}
            </HelpText>

            <H2>
                <HelpH2>{`${t(TranslationKeys.HELP.LOGIN_NOT_EMPLOYEE_HEADER)}`}</HelpH2>
            </H2>
            <HelpText fontWeight="bold">
                {t(TranslationKeys.HELP.LOGIN_DA_HEADER)}
            </HelpText>
            <ul>
                <li key="login_dsp_description">
                    <HelpText>
                        {t(TranslationKeys.HELP.LOGIN_DA_DESCRIPTION)}
                    </HelpText>
                </li>
            </ul>
            {showDspaModal() &&
                <>
                    <HelpText fontWeight="bold">
                        {t(TranslationKeys.HELP.LOGIN_DSP_HEADER)}
                    </HelpText>
                    <ul>
                        <li key="login_dsp_description">
                            <HelpText>
                                {t(TranslationKeys.HELP.LOGIN_DSP_DESCRIPTION)}
                            </HelpText>
                        </li>
                    </ul>
                </>
            }
            {showCsgoModal() && 
                <>
                    <HelpText fontWeight="bold">
                        {t(TranslationKeys.HELP.LOGIN_CSGO_HEADER)}
                    </HelpText>
                    <ul>
                        <li key="login_csgo_description">
                            <HelpText>
                                {t(TranslationKeys.HELP.LOGIN_CSGO_DESCRIPTION)}
                            </HelpText>
                        </li>
                    </ul>
                </>
            }
            {showSruModal() && 
                <>
                    <HelpText fontWeight="bold">
                        {t(TranslationKeys.HELP.LOGIN_SRU_HEADER)}
                    </HelpText>
                    <ul>
                        <li key="login_sru_description">
                            <HelpText>
                                {t(TranslationKeys.HELP.LOGIN_SRU_DESCRIPTION)}
                            </HelpText>
                        </li>
                    </ul>
                </>
            }
            {showPbModal() && 
                <>
                    <HelpText fontWeight="bold">
                        {t(TranslationKeys.HELP.LOGIN_PREBOARDER_HEADER)}
                    </HelpText>
                    <ul>
                        <li key="login_preboarder_description">
                            <HelpText>
                                {t(TranslationKeys.HELP.LOGIN_PREBOARDER_DESCRIPTION)}
                            </HelpText>
                        </li>
                    </ul>
                </>
            }

            <H2>
                <HelpH2 margin={"36px 0 0px 0"}>{t(TranslationKeys.HELP.FAQ_TITLE_HEADER)}</HelpH2>
            </H2>
            <HelpText>
                {t(TranslationKeys.HELP.FAQ_DOES_NOT_APPLY)}
            </HelpText>
            <H3>
                <HelpH3>{t(TranslationKeys.HELP.FAQ_ENTER_LOGIN_HEADER)}</HelpH3>
            </H3>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_ENTER_LOGIN_QUESTION)}</HelpH4>
            </H4>
            <HelpText>
                {t(TranslationKeys.HELP.FAQ_ENTER_LOGIN_ANSWER)}
            </HelpText>
            <HelpText>
                {t(TranslationKeys.HELP.FAQ_ENTER_LOGIN_ALUMNI_ANSWER2)} {t(TranslationKeys.HELP.FAQ_ENTER_LOGIN_ALUMNI_ANSWER3)}
            </HelpText>
            <H3>
                <HelpH3>{t(TranslationKeys.HELP.FAQ_SELECT_MOBILE_PHONE_EMAIL_HEADER)}</HelpH3>
            </H3>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_SELECT_MOBILE_PHONE_EMAIL_QUESTION)}</HelpH4>
            </H4>
            <HelpText>
                {t(TranslationKeys.HELP.FAQ_SELECT_MOBILE_PHONE_EMAIL_ANSWER)}
            </HelpText>
            <H3>
                <HelpH3>{t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_HEADER)}</HelpH3>
            </H3>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_QUESTION_1)}</HelpH4>
            </H4>
            <View margin="0 0 16px 0">
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_1A)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_1B)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_1C)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_1D)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_1E)}
                </HelpText>
            </View>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_QUESTION_2)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_2)}
                <br />
                {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI)}
                <ol style={{paddingInlineStart: '23px'}}>
                    <li key='faq_verification_code_answer_alumni_sms'>
                        {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_SMS)}
                    </li>
                    <li key='faq_verification_code_answer_alumni_email'>
                        {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_EMAIL)}
                    </li>
                    <li key='faq_verification_code_answer_alumni_reset_password'>
                        {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_RESET_PASSWORD)}
                    </li>
                </ol>
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_QUESTION_3)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_3)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_QUESTION_4)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_VERIFICATION_CODE_ANSWER_4)}
            </HelpText>

            <H3>
                <HelpH3>{t(TranslationKeys.HELP.FAQ_PASSWORD_HEADER)}</HelpH3>
            </H3>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_1)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_1)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_2)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_2)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_3)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_3)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_4)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_4)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_5)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_5)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_6)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_6)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_7)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_7)}
            </HelpText>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_PASSWORD_QUESTION_8)}</HelpH4>
            </H4>
            <HelpText margin="0 0 16px 0">
                {t(TranslationKeys.HELP.FAQ_PASSWORD_ANSWER_8)}
            </HelpText>

            <H3>
                <HelpH3>{t(TranslationKeys.HELP.FAQ_AMAZON_JOBS_HEADER)}</HelpH3>
            </H3>
            <H4>
                <HelpH4>{t(TranslationKeys.HELP.FAQ_AMAZON_JOBS_QUESTION)}</HelpH4>
            </H4>
            <View margin="0 0 16px 0">
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_AMAZON_JOBS_ANSWER_INTERNAL)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_AMAZON_JOBS_ANSWER_EXTERNAL)}
                </HelpText>
                <HelpText>
                    {t(TranslationKeys.HELP.FAQ_AMAZON_JOBS_ANSWER_ALUMNI)}
                </HelpText>
            </View>

            <H2>
                <HelpH2>
                    {t(TranslationKeys.HELP.NEED_MORE_HELP_HEADER)}
                </HelpH2>
            </H2>
            <View>
                <HelpText margin="0 0 16px 0">
                    {t(TranslationKeys.HELP.NEED_MORE_HELP_DESCRIPTION)}
                </HelpText>
                {Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).map((index?: any) => (
                    <View key={`password-question-${index}`}>
                        <HelpText>
                            {t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}`])}<br/>
                        </HelpText>
                        <HelpText margin="0 0 20px 0">
                            {t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}_CONTACT`],
                                mapValues(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP[index],
                                    countryKey => FaqErcContacts.CONTACTS[countryKey]))}
                        </HelpText>
                    </View>
                ))}
            </View>
        </Container>
    );
}

export default HelpContent;