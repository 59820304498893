import React from 'react';
import { useTranslation } from "react-i18next";
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import { H1 } from '@amzn/stencil-react-components/text';
import AssetsLogo from 'src/images/logo.png';
import TranslationKeys from 'src/constants/translation-keys';
import Logo from './Logo';
import { recordEvent } from 'src/analytics/amplify';
import GetHelpFlyout from './GetHelpFlyout';

type Props = {
    screenName: string;
    selectionTitle: string;
    hideNotSure?: boolean;
}

interface SelectionContainerProps extends React.PropsWithChildren<Props> {
}

function handleFlyoutClick(screenName: string, flyoutType: string) {
    recordEvent({
      name: `${screenName}.${flyoutType}.Click`,
      immediate: true
    });
  }

const SelectionContiner = ({screenName, selectionTitle, hideNotSure, children}: SelectionContainerProps) => {
    const {t} = useTranslation();
    return (
        <Container>
            <Col>
                <Logo padding={{ bottom: 20 }}
                      src={AssetsLogo} />
                <View padding={{ top: 20, bottom: 20 }}>
                    <H1 fontWeight={700} fontSize="16px" lineHeight="24px">
                        {selectionTitle}
                    </H1>
                </View>
                {children}
                {!hideNotSure && 
                    <View onClick={() => handleFlyoutClick(screenName, 'NotSure')}>
                        <GetHelpFlyout buttonText={t(TranslationKeys.UI.BUTTONS.NOT_SURE)} 
                                       hideButtonIcon={true} />
                    </View>
                }
            </Col>
        </Container>
    )
}

export default SelectionContiner;