import React, { useEffect, useState } from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout';
import { useTranslation } from 'react-i18next';
import { Text } from "@amzn/stencil-react-components/text";
import TranslationKeys from 'src/constants/translation-keys';
import IconQuestionCircleBlue from 'src/images/icon_question_circle_blue.svg';
import HelpContent from './HelpContent';

type Props = {
    buttonText: string,
    hideButtonIcon?: boolean
}

export default function GetHelpFlyout({buttonText, hideButtonIcon}: Props) {
    const { t } = useTranslation();

    const [userHasSeenFlyout, setUserHasSeenFlyout] = useState(false);

    useEffect(() => {
        setUserHasSeenFlyout(false);
    }, [userHasSeenFlyout]);


    const onClose = () => {
        setUserHasSeenFlyout(true);
    };

    const renderFlyout = ({ close }: { close: () => void }) => (
        <FlyoutContent
            titleText={t(TranslationKeys.HELP.TITLE_HEADER)}
            onCloseButtonClick={close} 
            buttons={[
                <Button data-testid="get-help-flyout-close-button"
                        onClick={close}
                        key={'close-button-key'}
                        variant={ButtonVariant.Primary}>
                     {t(TranslationKeys.UI.BUTTONS.CLOSE)}
                </Button>
            ]}>
            <HelpContent />
        </FlyoutContent>
    );

    return (
        <WithFlyout renderFlyout={renderFlyout} onClose={onClose}>
            {({open}) => (
                <Button variant={ButtonVariant.Tertiary}
                        data-testid="get-help-flyout-button"
                        onClick={open}>
                    {hideButtonIcon ? '' : <img src={IconQuestionCircleBlue} width="14.4" height="14.4" aria-hidden={true} />}
                    <Text color="primary70"
                          data-testid="text-get-help"
                          className='common-footer-text'>
                        {buttonText}
                    </Text>
                </Button>
            )}
        </WithFlyout>
    );
}
