import React from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import AssertsGlobe from '../images/assets_globe.svg';

export default function Footer({ forbidden = false }) {
    const { t } = useTranslation();

    function changeLanguage(value: string) {
        i18n.changeLanguage(value, () => {
        }).then();
    }

    const selectClassName = forbidden ? 'chosen-select language-select-forbidden meta-size' : 'chosen-select language-select meta-size';

    return (
        <>
            <form action="#" method="POST" id="dropDownForm">
            </form>
            <div className="help-container txt-cntr">
                <div className="globe-container">
                    <img src={AssertsGlobe} />
                </div>
                <div className="dropdown-container">
                    <select id="changeLocaleDropdown" className={selectClassName} defaultValue={"en-US"} data-placeholder='' onChange={e => changeLanguage(e.target.value)}>
                        <option value="en-US">English</option>
                        <option value="ar-AE">العربية</option>
                        <option value="cs-CZ">Čeština</option>
                        <option value="de-DE">Deutsch</option>
                        <option value="es-ES">Español</option>
                        <option value="es-ES">Español (América Latina)</option>
                        <option value="fr-CA">Français (Canada)</option>
                        <option value="fr-FR">Français</option>
                        <option value="it-IT">Italiano</option>
                        <option value="ja-JP">日本語</option>
                        <option value="ko-KR">한국어</option>
                        <option value="ne-NP">नेपाली</option>
                        <option value="nl-NL">Nederlands</option>
                        <option value="pl-PL">Polski</option>
                        <option value="es-CO">Português (Brasil)</option>
                        <option value="ro-RO">Română</option>
                        <option value="ru-RU">Русский</option>
                        <option value="sk-SK">Slovenčina</option>
                        <option value="so-SO">Soomaali</option>
                        <option value="tr-TR">Türkçe</option>
                        <option value="uk-UA">Українська</option>
                        <option value="zh-CN">简体中文</option>
                        <option value="zh-TW">繁體中文</option>
                    </select>
                </div>
                <div className="help-content-container">
                    <div className="help-content">
                        <p className="meta grey-base zero-margin">
                            <span id="footer-need-help">{t('footer_need_help')}</span>
                            <a href="#" className="meta left-margin" data-target="#faqModal" data-toggle="modal" id="faqModalLink"> {t('footer_faq_link')} </a>
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
}