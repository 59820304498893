import { Analytics } from '@aws-amplify/analytics';
import { AnalyticsEvent, EventAttributes } from '@aws-amplify/analytics/lib-esm/types';
import { Auth } from '@aws-amplify/auth';
import katalTemplateString from 'src/helpers/katalHelper';
import Config from 'src/analytics/dev-config';
import Attribute from 'src/constants/attribute';
import CookieHelper from 'src/helpers/cookieHelper';
import CookiesConstants from 'src/constants/cookies';

const COGNITO_IDENTITY_POOL_ID = katalTemplateString('{{CognitoIdentityPoolId}}', Config.COGNITO_IDENTITY_POOL_ID_DEV);
const PINPOINT_APP_ID = katalTemplateString('{{PinpointAppId}}', Config.PINPOINT_APP_ID_DEV);


const authConfig = {
    Auth: {
        identityPoolId: COGNITO_IDENTITY_POOL_ID,
        region: 'us-west-2',
    }
}

const analyticsConfig = {
    AWSPinpoint: {
        appId: PINPOINT_APP_ID,
        region: 'us-west-2',
        mandatorySignIn: false,
    }
}

const pageViewConfig = {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    attributes: {
      referrer: document.referrer,
    },
    provider: 'AWSPinpoint',
    getUrl: () => window.location.href.toString(),
  };

function initializeAmplify(): void {
    Auth.configure(authConfig);
    Analytics.configure(analyticsConfig);
    Analytics.autoTrack('pageView', pageViewConfig);
    Analytics.enable();
}

export function recordEvent(event: AnalyticsEvent): void {
    let attributes: EventAttributes = event.attributes || {};

    const attributeCookieMap = new Map<string, string>();
    attributeCookieMap.set(Attribute.PERSON_ID, CookieHelper.readCookie(CookiesConstants.PERSON_ID));
    attributeCookieMap.set(Attribute.USERNAME, CookieHelper.readCookie(CookiesConstants.USERNAME));
    attributeCookieMap.set(Attribute.REFERRER, CookieHelper.readCookie(CookiesConstants.REFERRER));
    attributeCookieMap.set(Attribute.SESSION_ID, CookieHelper.readCookie(CookiesConstants.SESSION_ID));

    // Only track previous page for screens since the value would change nondeterministically in children components
    if (event.name.includes('Screen')) {
        attributeCookieMap.set(Attribute.PREVIOUS_PAGE, CookieHelper.readCookie(CookiesConstants.PREVIOUS_PAGE));
    }
    attributes = setAttributesFromMap(attributes, attributeCookieMap);

    event.attributes = attributes;

    Analytics.record(event);
}

function setAttributesFromMap(attributes: EventAttributes, attributeCookieMap: Map<string, string>) {
    attributeCookieMap.forEach((value, key) => {
        // we will ignore empty string ('') values in the map when setting the attributes
        if (value) {
            attributes[key] = value;
        }
    } );
    return attributes;
}

export default initializeAmplify;
