import { useTranslation } from "react-i18next";
import React from 'react';
import FaqErcContacts from 'src/constants/faq-erc-contacts';
import mapValues from 'lodash.mapvalues';
import TranslationKeys from "src/constants/translation-keys";

export default function FaqERCComponent() {
    const { t } = useTranslation();

    return(
        <>
            <div id="faqERC">
                <p id="faq-title-erc-contact" className="section-header">{t('faq_title_erc_contact')}</p>
                <p id="faq-question-erc-contact" className="question">{t('faq_question_erc_contact')}</p>

                {Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).map((index?: any) => (
                    <div key={`password-question-${index}`}>
                        {t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}`])}<br/>
                        {t(TranslationKeys.HELP[`FAQ_ERC_ANSWER1_${index}_CONTACT`],
                            mapValues(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP[index],
                                countryKey => FaqErcContacts.CONTACTS[countryKey]))}
                        <br/><br/>
                    </div>
                ))}

            </div>
        </>
    )
}