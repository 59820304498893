import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AwsCredsState {
    accessKey: string,
    secretKey: string,
    sessionToken: string,
    expiration: string
}

export const initialState: AwsCredsState = {
    accessKey: '',
    secretKey: '',
    sessionToken: '',
    expiration: ''
}

const awsCredsSlice = createSlice({
    name: 'aws-creds',
    initialState,
    reducers: {
        setAccessKey: (state: AwsCredsState, action: PayloadAction<string>) => {
            state.accessKey = action.payload;
        },
        setSecretKey: (state: AwsCredsState, action: PayloadAction<string>) => {
            state.secretKey = action.payload;
        },
        setSessionToken: (state: AwsCredsState, action: PayloadAction<string>) => {
            state.sessionToken = action.payload;
        },
        setExpiration: (state: AwsCredsState, action: PayloadAction<string>) => {
            state.expiration = action.payload;
        },
    }
});

export const { setAccessKey, setSecretKey, setSessionToken, setExpiration } = awsCredsSlice.actions;

export default awsCredsSlice.reducer;
