import React, { useEffect } from 'react';
import '../.././styles/alumni.scss';
import { saveCookies } from '../../hooks';
import ContactHelpView from './ContactHelpContentView';
import Footer from './Footer';
import { recordEvent } from 'src/analytics/amplify';

function ContactHelpScreen() {
    saveCookies();
    useEffect(() => {
        recordEvent({
            name: 'ContactHelpScreen.Render'
        });
    }, []);

    return(
        <div className="fullscreen">
            <div className="flex-container">
                <div data-testid="contactHelpBackground" className="flex-box-left-badge-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="contactHelpContent" className="content-wrap">
                       <ContactHelpView />
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer screen="ContactHelpScreen"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactHelpScreen;
