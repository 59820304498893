import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationKeys from 'src/constants/translation-keys';
import IconQuestionCircle from 'src/images/icon_question_circle.svg';
import { recordEvent } from 'src/analytics/amplify'
import { RouterLink } from '../RouterLink';
import { Text } from '@amzn/stencil-react-components/text';

//TODO: Add flyout with FAQ content once content finalized
export default function FaqFlyout() {
    const { t } = useTranslation();

    useEffect(() => {
        recordEvent({
            name: 'FaqFlyout.Render',
            immediate: true
        });
    }, []);

    return (
        <RouterLink textDecoration='none'
                data-testid="faq-flyout-button"
                to='/help#alumni'
                className="faq-flyout-container">
            <img src={IconQuestionCircle} alt="" width="16" height="16" />
            <Text className="footer-text" data-testid="footer-text-faq">
                {t(TranslationKeys.UI.ALUMNI.HELP_FOOTER)}
            </Text>
        </RouterLink>
    );
}
