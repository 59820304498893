import React from "react";
import { useTranslation } from "react-i18next";
import FaqERCComponent from "src/components/FaqERCComponent";

export default function FaqModal() {
    const { t } = useTranslation();

    return (
        <>
            <div className="modal in justfade" id="faqModal" tabIndex={-1} role="dialog" aria-labelledby="faqModal_label">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a className="pull-right">
                                <div className="close-icon" data-dismiss="modal"></div>
                            </a>
                            <h2 className="modal-title" id="faqModal_Label"> {t('faq_title')} </h2>
                        </div>
                        <div className="modal-body">
                            <p id="faq-title-enter-login" className="section-header">{t('faq_title_enter_login')}</p>
                            <p id="faq-question-enter-login" className="question">{t('faq_question_enter_login')}</p>
                            <p id="faq-answer-enter-login" className="answer">
                                {t('faq_answer_enter_login')}
                            </p>
                            <p id="faq-title-select-mobile-phone-email" className="section-header">{t('faq_title_select_mobile_phone_email')}</p>
                            <p id="faq-question-select-mobile-phone-email1" className="question">{t('faq_question_select_mobile_phone_email_1')}</p>
                            <p id="faq-answer-select-mobile-phone-email1" className="answer">
                                {t('faq_answer_select_mobile_phone_email_1')}
                            </p>
                            <p id="faq-question-select-mobile-phone-email2"className="question">{t('faq_question_select_mobile_phone_email_2')}</p>
                            <p id="faq-answer-select-mobile-phone-email2" className="answer">
                                {t('faq_answer_select_mobile_phone_email_2')}
                            </p>
                            <div id="faqCode">
                                <p id="faq-title-verification-code" className="section-header">{t('faq_title_verification_code')}</p>
                                <p id="faq-question-verification-code1" className="question">{t('faq_question_verification_code_1')}</p>
                                <div id="faq-answer-verification-code1" className="answer">
                                    <p>
                                        {t('faq_answer_verification_code_1a')}
                                    </p>
                                    <p>
                                        {t('faq_answer_verification_code_1b')}
                                    </p>
                                    <p>
                                        {t('faq_answer_verification_code_1c')}
                                    </p>
                                    <p>
                                        {t('faq_answer_verification_code_1d')}
                                    </p>
                                    <p>
                                        {t('faq_answer_verification_code_1e')}
                                    </p>
                                </div>
                                <p id="faq-question-verification-code2" className="question">{t('faq_question_verification_code_2')}</p>
                                <p id="faq-answer-verification-code2" className="answer">
                                    {t('faq_answer_verification_code_2')}
                                </p>
                                <p id="faq-question-verification-code3" className="question">{t('faq_question_verification_code_3')}</p>
                                <p id="faq-answer-verification-code3" className="answer">
                                    {t('faq_answer_verification_code_3')}
                                </p>
                                <p id="faq-question-verification-code4" className="question">{t('faq_question_verification_code_4')}</p>
                                <p id="faq-answer-verification-code4" className="answer">
                                    {t('faq_answer_verification_code_4')}
                                </p>
                            </div>
                            <div id="faqPassword">
                                <p id="faq-title-password" className="section-header">{t('faq_title_password')}</p>
                                <p id="faq-question-password1" className="question">{t('faq_question_password_1')}</p>
                                <p id="faq-answer-password1" className="answer">
                                    {t('faq_answer_password_1')}
                                </p>
                                <p id="faq-question-password2" className="question">{t('faq_question_password_2')}</p>
                                <p id="faq-answer-password2" className="answer">
                                    {t('faq_answer_password_2')}
                                </p>
                                <p id="faq-question-password3" className="question">{t('faq_question_password_3')}</p>
                                <p id="faq-answer-password3" className="answer">
                                    {t('faq_answer_password_3')}
                                </p>
                                <p id="faq-question-password4" className="question">{t('faq_question_password_4')}</p>
                                <p id="faq-answer-password4" className="answer">
                                    {t('faq_answer_password_4')}
                                </p>
                                <p id="faq-question-password5" className="question">{t('faq_question_password_5')}</p>
                                <p id="faq-answer-password5" className="answer">
                                    {t('faq_answer_password_5')}
                                </p>
                                <p id="faq-question-password6" className="question">{t('faq_question_password_6')}</p>
                                <p id="faq-answer-password6"className="answer">
                                    {t('faq_answer_password_6')}
                                </p>
                                <p id="faq-question-password7" className="question">{t('faq_question_password_7')}</p>
                                <p id="faq-answer-password7" className="answer">
                                    {t('faq_answer_password_7')}
                                </p>
                                <p id="faq-question-password8" className="question">{t('faq_question_password_8')}</p>
                                <p id="faq-answer-password8" className="answer">
                                    {t('faq_answer_password_8')}
                                </p>
                            </div>

                            <FaqERCComponent />
                        </div></div>
                </div>
            </div>
        </>
    );
}