import React, { useEffect, useState } from 'react';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ReactRoutes from '../../constants/react-routes';
import TranslationKeys from '../../constants/translation-keys';
import '../.././styles/alumni.scss';
import '../.././styles/app.scss'
import AlumniLogo from '../../images/alumni_logo.svg';
import { recordEvent } from 'src/analytics/amplify';
import {Button, ButtonSize, ButtonVariant} from "@amzn/stencil-react-components/button";
import {Input, InputWrapper} from "@amzn/stencil-react-components/form";
import {useSendInviteMutation} from "src/services/invite";
import {IconArrowLeft} from "@amzn/stencil-react-components/dist/submodules/icons";
import {createPrincipal} from "src/lib/principal";
import {useSearchParams} from "react-router-dom";

export default function CreateAccountView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [aliasInput, setAliasInput] = useState('');
    const [isError, setIsError] = useState(false);
    const [searchParams] = useSearchParams();
    const [footerMessage, setFooterMessage] = useState('');
    const [sendInvite] = useSendInviteMutation({
        fixedCacheKey: 'send-invite-button-mutation',
    });
    
    const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setAliasInput(e.target.value);
        if(e.target.value === '') {
            setIsError(false);
        }
    }

    useEffect(() => {
        recordEvent({
            name: 'CreateAccountView.Render',
        });
    }, []);

    function handleForgotAliasButtonClick() {
        recordEvent({
            name: 'CreateAccountView.ContactUsButton.Click',
            immediate: true
        });
        navigate(ReactRoutes.ALUMNI_FAQ);
    }

    function validInput() {
        if(aliasInput.includes('@')) {
            setIsError(true);
            setFooterMessage(t(TranslationKeys.UI.ALUMNI.CREATE_ACCOUNT_ERROR_MESSAGE));
            return false;
        }
        else {
            setIsError(false);
            setFooterMessage('');
        }
        return true;
    }

    function onContinueClick() {
        if (!validInput() || aliasInput === '') {
            return;
        }
        recordEvent({
            name: 'SendInviteButton.Click.Attempt',
            immediate: true,
        });
        
        const principal = createPrincipal(aliasInput);
        searchParams.set('username', aliasInput);
        sendInvite({
          principal,
        }).unwrap()
            .then(data => {
                if (data) {
                    recordEvent({
                        name: 'SendInviteButton.Click.Success',
                        immediate: true,
                    })
                }
            })
            .catch(error => {
                recordEvent({
                    name: 'SendInviteButton.Click.Failure',
                    immediate: true,
                })
                console.warn('failed to send invite', error)
            })
        navigate({pathname: ReactRoutes.ALUMNI_VERIFY_ACCOUNT_REQUEST, search: searchParams.toString()});
    }

    function handleBackButtonClick() {
        recordEvent({
            name: 'CreateAccountView.BackButton.Click',
            immediate: true,
        });
        window.location.href = "{{AlumniIdprismUrl}}";
    }

    return (
        <View className="alumni-content">
            <Col gridGap="S300">
                <Row gridGap="S500">
                    <Button
                        autoFocus={true}
                        size={ButtonSize.Default}
                        style={{color: '#232F3E'}}
                        data-testid='createAccountBackButton'
                        icon={<IconArrowLeft aria-hidden={true} />}
                        variant={ButtonVariant.Tertiary}
                        aria-label="Go back to login screen"
                        onClick={handleBackButtonClick}>
                    </Button>
                </Row>
                <View className="logo">
                    <img src={AlumniLogo} alt="Amazon A to Z"/>
                </View>
                <View className="header">
                    <div role="heading" aria-level={1} data-testid="createAccountHeader">
                        {t(TranslationKeys.UI.ALUMNI.CREATE_ACCOUNT_HEADER)}&emsp;&emsp;&emsp;&emsp;
                    </div>
                </View>
                <Col gridGap="S300">
                    <InputWrapper
                        labelText={t(TranslationKeys.UI.ALUMNI.CREATE_ACCOUNT_TEXTBOX_HEADER)}
                        id="createAccountInput"
                        footer= {isError ? footerMessage : undefined}
                        required
                        error={isError}
                    >
                        {inputProps => <Input data-testid='createAccountInput' value={aliasInput} onChange={handleInputChange} {...inputProps} />}
                    </InputWrapper>
                </Col>
                <Row gridGap="S500">
                    <Button
                        data-testid='forgotAliasButton'
                        variant={ButtonVariant.Tertiary}
                        size={ButtonSize.Small}
                        style={{paddingLeft: '0px'}}
                        onClick={handleForgotAliasButtonClick}>
                        {t(TranslationKeys.UI.BUTTONS.FORGOT_ALIAS)}
                    </Button>
                </Row>
                <Row gridGap="S500">
                    <Button data-testid='sendInviteButton' variant={ButtonVariant.Primary} style={{width : '100%'}} onClick={onContinueClick}>
                        {t(TranslationKeys.UI.BUTTONS.CONTINUE)}
                    </Button>
                </Row>
            </Col>
        </View>
    )
}
