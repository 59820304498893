import { View, ViewProps } from '@amzn/stencil-react-components/layout';
import React from 'react'
import { useTranslation } from "react-i18next";
import TranslationKeys from 'src/constants/translation-keys';

type LogoProps = {
    src: string;
} & ViewProps;

const Logo = (props: LogoProps) => {
    const { t } = useTranslation();
    return (
        <View className={`logo ${props.className}`} style={{paddingBottom: '40px'}}>
            <img data-testid='atoz-logo' src={props.src} alt={t(TranslationKeys.UI.IMAGE.ATOZ_LOGO_ALT)} />
        </View>
    );
}

export default Logo;