import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageBanner, MessageBannerLink, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import TranslationKeys from '../../constants/translation-keys';

export default function ErrorBanner() {
    const { t } = useTranslation();
    return (
        <MessageBanner data-testid='error-banner' type={MessageBannerType.Error}>
            {t(TranslationKeys.UI.ALUMNI.GENERIC_ERROR_MESSAGE)}
            <MessageBannerLink data-testid='error-banner-link' href='/help#alumni'>
                {t(TranslationKeys.UI.ALUMNI.GENERIC_ERROR_LINK)}
            </MessageBannerLink>
        </MessageBanner>
    );
}
