import React, { useEffect } from 'react';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { usePrincipal, saveCookies } from '../../hooks';
import { useGetContactInfoQuery } from '../../services/invite';
import ContactInfoSelectView from './ContactInfoSelectContentView';
import Footer from './Footer';
import ContactHelpErrorBoundary from './ContactHelpErrorBoundary';
import '../.././styles/alumni.scss';
import { recordEvent } from 'src/analytics/amplify';
import {useSearchParams} from "react-router-dom";
import {showNewActivationFlow} from "src/constants/feature-gates";
import ReactRoutes from "src/constants/react-routes";
import {useNavigate} from "react-router";

function ContactInfoSelectScreen() {
    saveCookies();
    const [principal] = usePrincipal();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get('code') || '';
    const { isLoading, error } = useGetContactInfoQuery({principal, code});
    let isLinkValid = true;
    if (error) {
        if (showNewActivationFlow() &&
            (error as any)?.status === 401 && (error as any)?.data.message.includes('Activation code')) {
            isLinkValid = false;
        }
    }

    useEffect(() => {
        if (!isLinkValid) {
            navigate({pathname: ReactRoutes.ALUMNI_ERROR_SCREEN, search: searchParams.toString()});
        }
        recordEvent({
            name: 'ContactInfoSelectScreen.Render'
        });
    }, [isLinkValid, navigate]);

    return(
        <ContactHelpErrorBoundary>
            <div className="fullscreen">
                <div className="flex-container">
                    <div data-testid="contactHelpBackground" className="flex-box-left-badge-background">
                    </div>
                    <div className="flex-box-right">
                        <div data-testid="contactInfoContent" className="content-wrap">
                            { isLoading ? <Spinner data-testid="selectSpinner" size={SpinnerSize.Medium} /> :
                                (isLinkValid && <ContactInfoSelectView />)
                            }
                        </div>
                        <div data-testid="footer" className="footer-container">
                            <Footer screen="ContactInfoSelectScreen"/>
                        </div>
                    </div>
                </div>
            </div>
        </ContactHelpErrorBoundary>
    )
}

export default ContactInfoSelectScreen;
