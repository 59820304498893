import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WelcomeInfoState {
    preferredEmailIndex: number,
    preferredPhoneIndex: number,
}

export const initialState: WelcomeInfoState = {
    
    preferredEmailIndex: 0,
    preferredPhoneIndex: 0,
}

const welcomeInfoSlice = createSlice({
    name: 'welcome-info',
    initialState,
    reducers: {
        setPreferredEmailIndex: (state: WelcomeInfoState, action: PayloadAction<number>) => {
            const index = action.payload || 0;
            state.preferredEmailIndex = index;
        },
        setPreferredPhoneIndex: (state: WelcomeInfoState, action: PayloadAction<number>) => {
            const index = action.payload || 0;
            state.preferredPhoneIndex = index;
        },
    }
});

export const {
    setPreferredEmailIndex,
    setPreferredPhoneIndex,
} = welcomeInfoSlice.actions;

export default welcomeInfoSlice.reducer;
