import React from 'react';
import { DetailedRadio } from '@amzn/stencil-react-components/form';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ReactRoutes from 'src/constants/react-routes';
import TranslationKeys from 'src/constants/translation-keys';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setPreferredEmailIndex, setPreferredPhoneIndex } from '../../redux/welcome-info-slice';
import { usePrincipal } from '../../hooks';
import { ContactInfo, useGetContactInfoQuery, useCreateAccountMutation } from '../../services/invite';
import { validateContactInfo } from '../../helpers/contactInfoHelper';
import AlumniLogo from '../../images/alumni_logo.svg';
import CreateAccountButton, { CREATE_ACCOUNT_BUTTON_MUTATION } from './CreateAccountButton';
import ErrorBanner from './ErrorBanner';
import '../.././styles/alumni.scss';
import { recordEvent } from 'src/analytics/amplify';

export default function ContactInfoSelectView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [principal] = usePrincipal();
    const code = searchParams.get('code') || '';
    const { data, error } = useGetContactInfoQuery({principal, code});
    if (error) {
        throw error;
    }
    validateContactInfo(data);
    const { emails, phoneNumbers } = data as ContactInfo;
    const [_, { isError }] = useCreateAccountMutation({
        fixedCacheKey: CREATE_ACCOUNT_BUTTON_MUTATION,
    });

    const preferredEmailIndex: number = useAppSelector(state => state.welcomeInfo.preferredEmailIndex);
    const preferredPhoneIndex: number = useAppSelector(state => state.welcomeInfo.preferredPhoneIndex);

    function navigateToContactHelpScreen() {
        navigate({pathname: ReactRoutes.ALUMNI_CONTACT_HELP, search: searchParams.toString()});
    }

    function renderEmails() {
        return emails?.map((value: string, index: number) => {
            return <DetailedRadio
                key={index}
                data-testid={'email-' + index}
                name="email-radio"
                value={"email-"+ index}
                titleText={value}
                defaultChecked={index === preferredEmailIndex}
                onChange={() => {
                    recordEvent({
                        name: 'PreferredEmail.Change',
                        immediate: true,
                        attributes: {
                          'previous': preferredEmailIndex.toString(),
                          'current': index.toString()
                        }
                    });
                    dispatch(setPreferredEmailIndex(index));
                }}
            />
        })
    };

    function renderPhones() {
        return phoneNumbers?.map((value: string, index: number) => {
            return <DetailedRadio
                key={index}
                data-testid={'phone-' + index}
                name="phone-radio"
                disabled={false}
                value={"phone-"+ index}
                titleText={value}
                defaultChecked={index === preferredPhoneIndex}
                onChange={() => {
                    recordEvent({
                        name: 'PreferredPhone.Change',
                        immediate: true,
                        attributes: {
                          'previous': preferredPhoneIndex.toString(),
                          'current': index.toString()
                        }
                    });
                    dispatch(setPreferredPhoneIndex(index));
                }}
            />
        })
    };

    return (
        <View className="alumni-content">
            <Col gridGap="S300">
                {isError && <ErrorBanner />}
                <View className="logo">
                    <img src={AlumniLogo}/>
                </View>
                <View className="header">
                    <div data-testid="contactInfoHeader">
                        {t(TranslationKeys.UI.ALUMNI.CONTACT_INFO_HEADER)}
                    </div>
                </View>
                <View className="description">
                    <div data-testid="emailHeader">
                        {t(TranslationKeys.UI.ALUMNI.CONTACT_INFO_EMAIL_HEADER)}
                    </div>
                </View>
                <Col gridGap="S300">
                    <Col gridGap="S200">
                        { renderEmails() }
                    </Col>
                </Col>
                <Link target="_blank"
                    onClick={() => {
                        recordEvent({
                            name: 'NoEmailsWork.Click',
                            immediate: true
                        });
                        navigateToContactHelpScreen();
                    }}
                >
                    {t(TranslationKeys.UI.ALUMNI.CONTACT_INFO_EMAIL_ALTERNATE)}
                </Link>
                <View className="description">
                    <div data-testid="phoneHeader">
                        {t(TranslationKeys.UI.ALUMNI.CONTACT_INFO_PHONE_HEADER)}
                    </div>
                </View>
                <Col gridGap="S300">
                    <Col gridGap="S200">
                        { renderPhones() }
                    </Col>
                </Col>
                <Link target="_blank"
                    onClick={() => {
                        recordEvent({
                            name: 'NoPhoneNumbersWork.Click',
                            immediate: true
                        });
                        navigateToContactHelpScreen();
                    }}
                >
                    {t(TranslationKeys.UI.ALUMNI.CONTACT_INFO_PHONE_ALTERNATE)}
                </Link>
                <CreateAccountButton />
            </Col>
        </View>
    )
}
