import React from 'react'
import { LinkCard } from '@amzn/stencil-react-components/card';
import { DataTestProps } from '@amzn/stencil-react-components/dist/submodules/utils';
import { recordEvent } from 'src/analytics/amplify';

type Props = {
    href: string;
    name: string;
    screenName: string;
    onClick?: () => void;
} & DataTestProps

const recordAndExecute = (name: string, screenName: string, onClick?: () => any) => {
    recordEvent({
      name: `${screenName}.${name}.Count`,
      immediate: true
    });
    onClick && onClick();
}

interface SelectionTileProps extends React.PropsWithChildren<Props> {
}

const SelectionTile = ({dataTestId, href, name, screenName, onClick, children} : SelectionTileProps) => {
    const className = `content-box selection-tile-container`
    return (
        <LinkCard data-testid={dataTestId}
                  className={className}
                  style={{boxShadow: '0px 1px 10px 0px rgba(35, 47, 62, 0.2)'}}
                  padding={{top: '16px', bottom: '16px', left: '12px', right: '12px'}}
                  href={href}
                  onClick={() => {recordAndExecute(name, screenName, onClick)}}>
            {children}
        </LinkCard>
    )
}

export default SelectionTile;