import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import apigClientFactory, { ApiGatewayClientConfig, Response } from 'aws-api-gateway-client';
import store from '../redux/store';
import getAwsClientsConfig from './awsClientsConfig';
import { setAccessKey, setSecretKey, setSessionToken, setExpiration } from "../redux/aws-creds-slice";

async function refreshGuestAWSCreds() {
    const config = getAwsClientsConfig();
    const creds = fromCognitoIdentityPool({
        identityPoolId: config.identityPoolId,
        clientConfig: {
            region: config.region
        }
    })

    const data = await creds();

    store.dispatch(setAccessKey(data.accessKeyId));
    store.dispatch(setSecretKey(data.secretAccessKey));

    if (data.sessionToken) {
        store.dispatch(setSessionToken(data.sessionToken));
    }

    if (data.expiration) {
        store.dispatch(setExpiration(data.expiration.toISOString()));
    }

    return data;
}

// Wrapper to refresh creds before initializing the client
async function getApigClientInvokeApiWrapper(
    initApigClientConfig: any,
    pathParams: Record<string, unknown>,
    pathTemplate: string,
    method: 'GET' | 'POST' | 'DELETE' | 'UPDATE',
    additionalParams?: {
        headers?: { [key: string]: unknown };
        queryParams?: { [key: string]: string | number | boolean };
        timeout?: number;
    },
    body?: Record<string, unknown>,
) {
    const config = getAwsClientsConfig();
    const state = store.getState();
    const awsCreds = state.awsCreds;
    const expiration = awsCreds.expiration;
    if (
        !awsCreds.accessKey ||
        !awsCreds.secretKey ||
        !awsCreds.sessionToken ||
        !expiration ||
        new Date() > new Date(expiration) // credentials has expired
    ) {
        await refreshGuestAWSCreds();
    }

    const apigClientConfig: ApiGatewayClientConfig = {
        ...initApigClientConfig,
        ...getAwsCreds(),
        region: config.region,
        host: config.host
    }
    const apiClient = apigClientFactory.newClient(apigClientConfig);

    // Returns a Promise
    // Using JSON.stringify(body) will get you a 400 bad request error.
    // Converting the body to a string this way works.
    return apiClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body as unknown as string);
}

function getAwsCreds() {
    const state = store.getState();
    const awsCreds = state.awsCreds;
    return {
        accessKey: awsCreds.accessKey,
        secretKey: awsCreds.secretKey,
        sessionToken: awsCreds.sessionToken
    }
}

// generalized api call function for flexible data access
export function invokeInviteApi(
    path: string,
    method: 'GET' | 'POST' | 'DELETE' | 'UPDATE',
    query?: { [key: string]: string | number | boolean },
    body?: Record<string, unknown>
): Promise<Response> {
    const config = getAwsClientsConfig();
    const initApigClientConfig = {
        invokeUrl: config.atoZInvitationRestApiBaseUrl,
    };
    return getApigClientInvokeApiWrapper(initApigClientConfig, {}, path, method, { queryParams: query }, body);
}
