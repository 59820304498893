type rootConfigType = {
    [key: string]: {[key: string]: any}
}

const rootConfig : rootConfigType = {
    // TODO: Add documentation to provision some dotenv file to contain developer specific values
    // tungsmat creds
    dev: {
        region: 'us-west-2',
        identityPoolId: 'us-west-2:6180a7d6-f6d5-41b6-bfd9-5fb27471a84a',
        atoZInvitationRestApiBaseUrl: 'https://us-west-2.gamma.invite.atoz.amazon.dev',
    },
    beta: {
        region: 'us-west-2',
        identityPoolId: 'us-west-2:6180a7d6-f6d5-41b6-bfd9-5fb27471a84a',
        atoZInvitationRestApiBaseUrl: 'https://atoz-login.integ.amazon.com',
        host: 'us-west-2.gamma.invite.atoz.amazon.dev'
    },
    gamma: {
        region: 'us-west-2',
        identityPoolId: 'us-west-2:6180a7d6-f6d5-41b6-bfd9-5fb27471a84a',
        atoZInvitationRestApiBaseUrl: 'https://atoz-login-gamma.corp.amazon.com',
        host: 'us-west-2.gamma.invite.atoz.amazon.dev'
    },
    'prod-na': {
        region: 'us-west-2',
        identityPoolId: 'us-west-2:304a72f7-955c-4b90-915e-e8c41e610605',
        atoZInvitationRestApiBaseUrl: 'https://atoz-login.amazon.work',
        host: 'us-west-2.prod.invite.atoz.amazon.dev'
    }
};

export default function getAwsClientsConfig() {
    return rootConfig['{{Stage}}'.toLowerCase()];
}
