import React from 'react'
import { useTranslation } from "react-i18next";
import { UserType } from "src/constants/usertype";
import RightChevron from 'src/images/icon_rightchevron.svg';
import LeftChevron from 'src/images/icon_leftchevron.svg';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { recordEvent } from 'src/analytics/amplify';

interface Props {
    userType: UserType;
    screenName: string;
    header: string;
    description: string;
    iconSrc: string;
    href?: string;
    onClick?: () => any;
    hidden?: boolean;
}

const recordAndExecute = (userType: string, screenName: string, onClick?: () => any) => {
    recordEvent({
      name: `${screenName}.${userType}.Count`,
      immediate: true
    });
    onClick && onClick();
}

const UserSelectionTile = ({userType,
                            screenName,
                            header,
                            description,
                            iconSrc,
                            href,
                            onClick,
                            hidden = false}: Props) => {
    const { t, i18n } = useTranslation();
    const className = `content-box selection-tile-container ${hidden ? 'hidden' : ''}`
    const userTypeString = userType.toLowerCase();
    const divId = `${userTypeString}-picker`;
    const textDivId = `${userTypeString}-login-text`;
    const rtl = i18n.dir() === 'rtl';
    
    return (
        <LinkCard data-testid={`${userTypeString}-tile`}
                  id={divId} 
                  className={className}
                  style={{boxShadow: '0px 1px 10px 0px rgba(35, 47, 62, 0.2)'}}
                  padding={{top: '16px', bottom: '16px', left: '12px', right: '12px'}}
                  onClick={() => {recordAndExecute(userType.toUpperCase(), screenName, onClick)}}
                  href={href}>
            <img className={`icon${rtl ? "-rtl" : ""}`} src={iconSrc} alt="" aria-hidden={true} />
            <div id={textDivId}>
                <div className='tile-header'>{t(header)}</div>
                <div className='tile-description'>{t(description)}</div>
            </div>
            <img className="chevron" src={rtl ? LeftChevron : RightChevron} alt="" aria-hidden={true} />
        </LinkCard>
    )
}

export default UserSelectionTile;