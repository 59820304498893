import React, {useRef, useState} from 'react'
import { useTranslation } from "react-i18next";
import CookieHelper from "src/helpers/cookieHelper";
import Cookies from "src/constants/cookies";
import { getUrlParameter } from 'src/js/util';
import { View } from '@amzn/stencil-react-components/layout';
import { Button } from '@amzn/stencil-react-components/button';
import { Label } from '@amzn/stencil-react-components/text';
import { Input } from '@amzn/stencil-react-components/form';
import TranslationKeys from 'src/constants/translation-keys';
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import ParameterNames from "src/constants/parameter-names";
import {clearRequestSource} from "src/redux/request-source-slice";

const LoginForm = () => {
    const { t } = useTranslation();
    const [login, setLogin] = useState('');

    const formRef = useRef<HTMLFormElement | null>(null);
    const dispatch = useAppDispatch();
    const source: string = useAppSelector(state => state.requestSource.value);

    const aaAuthenticationControllerUrl: string =
        (source === ParameterNames.REQUEST_SOURCE_AUTHENTICATION_CONTROLLER ) ?
            "{{AaAuthenticationControllerUrl}}" : "{{AaEsspUrl}}";

    const setLoginHintCookie = () => {
        CookieHelper.createCookie(Cookies.LOGIN_HINT, login, 30, '/', CookieHelper.resolveDomainStringForCookie());
    };

    const getProviderId = () => {
        return getUrlParameter('providerId',
            aaAuthenticationControllerUrl.split('?')[1]);
    };

    const handleOnClick = () => {
        formRef.current!.action = aaAuthenticationControllerUrl;
        setLoginHintCookie();
        dispatch(clearRequestSource());
    }

    return (
        <View id="login-panel" 
              className={"content-box username-content"} 
              data-testid='login-form-view'
              borderRadius={"5px"} 
              margin={{top: "10px", bottom: "10px"}} >
            <View id='subtle-picker'>
                <h1 id="login-form-header"> {t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_HEADER)} </h1>
                <p id="associate-login-text" className='login-form-context'>
                    {t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_TEXT1)}
                    <br/>
                    {t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_TEXT2)}
                </p>
                <form ref={formRef} data-testid='aa-login-form' id="aa-login-form" method="GET">
                    <input type="hidden" name="loginType" value="AA" />
                    <input id="providerId-hidden-field" type="hidden" name="providerId" value={getProviderId()} />
                    <View className="form-group">
                        <Label id="login-label" htmlFor="associate-login-input">
                            <p id="amazon-login-text" className="login-form-label"> {t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_LABEL)} </p>
                        </Label>
                        <Input id="associate-login-input" type="text" className="form-control" required={true}
                               name="login" autoFocus maxLength={10} placeholder={t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_INPUT_HINT)}
                               value={login} onChange={(e) => { setLogin(e.target.value) }} aria-labelledby='login-label'/>
                    </View>
                    <View className="form-buttons">
                        <Button id="login-form-login-btn" type="submit" className="btn btn-primary full-width" onClick={handleOnClick}>
                            {t(TranslationKeys.UI.BUTTONS.LOGIN)}
                        </Button>
                    </View>
                </form>
            </View>
        </View>
    );
}

export default LoginForm;