import React, {useEffect, useState} from 'react';
import {Col, Row, View} from '@amzn/stencil-react-components/layout';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import TranslationKeys from '../../constants/translation-keys';
import '../.././styles/alumni.scss';
import AlumniLogo from '../../images/alumni_logo.svg';
import {recordEvent} from 'src/analytics/amplify';
import {Button, ButtonSize, ButtonVariant} from "@amzn/stencil-react-components/button";
import {IconCheckCircleFill} from "@amzn/stencil-react-components/icons";
import {useSendInviteMutation} from "src/services/invite";
import {usePrincipal} from "src/hooks";
import {useSearchParams} from "react-router-dom";

const RESEND_DURATION = 30;

export default function InvalidLinkContentView() {
    const { t } = useTranslation();
    const [principal] = usePrincipal();
    const [showButton, setShowButton] = useState(true);
    const [timer, setTimer] = useState(30);
    const [sendInvite] = useSendInviteMutation({
        fixedCacheKey: 'send-invite-button-mutation',
    });
    const [searchParams] = useSearchParams();
    const restartSignupProcess = 'true' === searchParams.get('triggerEmail');

    useEffect(() => {
        recordEvent({
            name: 'InvalidLinkContentView.Render',
        });
        if (restartSignupProcess) {
            sendInvite({
                principal,
            }).unwrap()
                .then(() => {
                    recordEvent({
                        name: 'InvalidLinkContentView.EmailTrigger.Success',
                        immediate: true,
                    })
                })
                .catch(error => {
                    recordEvent({
                        name: 'InvalidLinkContentView.EmailTrigger.Failure',
                        immediate: true,
                    })
                    console.error('failed to send email', error)
                })
        }
    }, []);

    function handleResend() {
        setShowButton(false);
        let countdown = RESEND_DURATION;
        const interval = setInterval(() => {
            countdown -= 1;
            setTimer(countdown);
            if (countdown <= 0) {
                clearInterval(interval);
                setShowButton(true);
                setTimer(RESEND_DURATION);
            }
        }, 1000);
        recordEvent({
            name: 'InvalidLinkContentView.ResendButton.Click',
            immediate: true
        });
        sendInvite({
            principal,
        }).unwrap()
            .then(() => {
                recordEvent({
                    name: 'InvalidLinkContentView.ResendButton.Click.Success',
                    immediate: true,
                })
            })
            .catch(error => {
                recordEvent({
                    name: 'InvalidLinkContentView.ResendButton.Click.Failure',
                    immediate: true,
                })
                console.error('failed to re-send invite', error)
            })
    }

    return (
        <View className="alumni-content">
            <Col gridGap="S300">
                <View className="logo">
                    <img src={AlumniLogo} alt="Amazon A to Z"/>
                </View>
                <View className="header">
                    <div role="heading" aria-level={1} data-testid="invalidLinkHeader">
                        {restartSignupProcess ? t(TranslationKeys.UI.ALUMNI.RESTART_SIGNUP_PROCESS_HEADER) : t(TranslationKeys.UI.ALUMNI.INVALID_LINK_HEADER)}
                    </div>
                </View>
                <View className="description">
                    <div data-testid="invalidLinkBody">
                        {restartSignupProcess ? t(TranslationKeys.UI.ALUMNI.RESTART_SIGNUP_PROCESS_DESCRIPTION) : t(TranslationKeys.UI.ALUMNI.INVALID_LINK_DESCRIPTION)}
                    </div>
                </View>
                {!restartSignupProcess && <View className="description" data-testid="updateAppMessage">
                        {t(TranslationKeys.UI.ALUMNI.UPDATE_APP_MESSAGE)}
                </View>}
                
                <Row gridGap="S500">
                    {showButton ? (<Button
                            data-testid="resendLinkButton"
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                            className="resend-email-button"
                            onClick={handleResend}>
                            {t(TranslationKeys.UI.BUTTONS.RESEND_EMAIL)}
                        </Button>) :
                        <View className="description" data-testid='resendLinkDescription'>
                            <Row gridGap="S100">
                                <IconCheckCircleFill aria-hidden={true} color="green70"/> <b>{t(TranslationKeys.UI.ALUMNI.RESEND_EMAIL_HEADER, {timer})}</b>
                            </Row>
                        </View>
                    }
                </Row>
            </Col>
        </View>
    )
}
