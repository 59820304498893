import React from 'react';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ReactRoutes from 'src/constants/react-routes';
import TranslationKeys from 'src/constants/translation-keys';
import { useAppSelector } from '../../redux/hooks';
import { usePrincipal } from '../../hooks';
import { ContactInfo, useGetContactInfoQuery, useCreateAccountMutation } from '../../services/invite';
import { validateContactInfo } from '../../helpers/contactInfoHelper';
import AlumniLogo from '../../images/alumni_logo.svg';
import CreateAccountButton, { CREATE_ACCOUNT_BUTTON_MUTATION } from './CreateAccountButton';
import ErrorBanner from './ErrorBanner';
import '../../styles/alumni.scss';
import { recordEvent } from 'src/analytics/amplify';

export default function WelcomeContentView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [principal] = usePrincipal();
    const code = searchParams.get('code') || '';
    const { data, error } = useGetContactInfoQuery({principal, code});
    if (error) {
        throw error;
    }
    validateContactInfo(data);
    const { firstName, emails, phoneNumbers } = data as ContactInfo;
    const [_, { isError }] = useCreateAccountMutation({
        fixedCacheKey: CREATE_ACCOUNT_BUTTON_MUTATION,
    });

    const preferredEmailIndex: number = useAppSelector(state => state.welcomeInfo.preferredEmailIndex);
    const preferredPhoneIndex: number = useAppSelector(state => state.welcomeInfo.preferredPhoneIndex);

    function handleChooseAlternateContact() {
        recordEvent({
            name: 'WelcomeContentView.ChooseAlternateContact',
        });
        navigate({pathname: ReactRoutes.ALUMNI_CONTACT_SELECT, search: searchParams.toString()});
    }

    return (
        <View className="alumni-content">
            <Col gridGap="S400">
                {isError && <ErrorBanner />}
                <View className="logo">
                    <img src={AlumniLogo}/>
                </View>
                <View className="header">
                    <div data-testid="welcomeHeader">
                        {t(TranslationKeys.UI.ALUMNI.WELCOME_HEADER, { 0: firstName})}
                    </div>
                </View>
                <View className="description">
                    <div data-testid="welcomeBody">
                        {t(TranslationKeys.UI.ALUMNI.WELCOME_DESCRIPTION)}
                    </div>
                </View>
                <Row gridGap="S300">
                    <div className="setup-step-number">1</div>
                    <div className="setup-step">
                        { emails && t(TranslationKeys.UI.ALUMNI.SETUP_STEP1, { 0: emails[preferredEmailIndex] })}
                    </div>
                </Row>
                <Row gridGap="S300">
                    <div className="setup-step-number">2</div>
                    <div className="setup-step">
                        {t(TranslationKeys.UI.ALUMNI.SETUP_STEP2)}
                    </div>
                </Row>
                <Row gridGap="S300">
                    <div className="setup-step-number">3</div>
                    <div className="setup-step">
                        { phoneNumbers && t(TranslationKeys.UI.ALUMNI.SETUP_STEP3, { 0: phoneNumbers[preferredPhoneIndex] })}
                    </div>
                </Row>
                <Link data-testid="alternateContact" className="anotherClass" onClick={handleChooseAlternateContact}>
                    {t(TranslationKeys.UI.ALUMNI.SETUP_ALTERNATE_CONTACT)}
                </Link>
                <View className="label-text" alignSelf='center' width="100%">
                    <CreateAccountButton />
                </View>
            </Col>
        </View>
    )
}
