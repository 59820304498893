const locales = [
    {
        name: "العربية",
        value: "ar-SA"
    },
    {
        name: "Čeština",
        value: "cs-CZ"
    },
    {
        name: "Deutsch",
        value: "de-DE"
    },
    {
        name: "English",
        value: "en-US"
    },
    {
        name: "Español",
        value: "es-ES"
    },
    {
        name: "Español (América Latina)",
        value: "es-MX"
    },
    {
        name: "Français (Canada)",
        value: "fr-CA"
    },
    {
        name: "Français",
        value: "fr-FR"
    },
    {
        name: "हिंदी",
        value: "hi-IN"
    },
    {
        name: "Kreyòl Ayisyen",
        value: "ht-HT"
    },
    {
        name: "Italiano",
        value: "it-IT"
    },
    {
        name: "日本語",
        value: "ja-JP"
    },
    {
        name: "한국어",
        value: 'ko-KR'
    },
    {
        name: 'नेपाल',
        value: 'ne-NP'
    },
    {
        name: "Nederlands",
        value: "nl-NL"
    },
    {
        name: "Polski",
        value: "pl-PL"
    },
    {
        name: "Português (Brasil)",
        value: "pt-BR"
    },
    {
        name: "Română",
        value: "ro-RO"
    },
    {
        name: "русский",
        value: "ru-RU"
    },
    {
        name: "slovenský jazyk",
        value: "sk-SK"
    },
    {
        name: "Soomaali",
        value: "so-SO"
    },
    {
        name: "ትግርኛ",
        value: "ti-ER"
    },
    {
        name: "Türk",
        value: "tr-TR"
    },
    {
        name: "Ukrayna",
        value: "uk-UA"
    },
    {
        name: "简体中文",
        value: "zh-CN"
    },
    {
        name: "繁體中文",
        value: "zh-TW"
    }
];

function createLocaleArray() {
    let localeArray = new Array();
    locales.map(e => {
        localeArray.push(e.value);
    });
    return localeArray;
}

function createLangArray() {
    let langArray = new Array();
    locales.map(e => {
        langArray.push(e.name);
    });
    return langArray;
}

function createLangMap() {
    let langMap = new Map();
    locales.map(val => {
        langMap.set(val.name, val.value);
    })
    return langMap;
}

module.exports = {
    localeArray: createLocaleArray(),
    langArray: createLangArray(),
    langMap: createLangMap(),
};