// matches personId
const UUID_REGEX = '^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$'

/**
 * Describes the acting user by PersonID or username, which may be an email or Amazon alias.
 */
export type Principal = {
    readonly key: 'id' | 'username';
    readonly value: string;
}

/**
 * Creates a Principal instance from a principal string value. If the value matches a PersonId,
 * then the principal is an ID principal, otherwise it is a username principal.
 * @param principalParam string value
 * @returns Principal 
 */
export function createPrincipal(principalParam: string): Principal {
    const principalKey = principalParam && principalParam.match(UUID_REGEX) ? 'id' : 'username';
    return {
        key: principalKey,
        value: principalParam,
    };
}
