import { useState, useEffect } from 'react';
import ar from './ar.json';
import cs from './cs-CZ.json';
import de from './de-DE.json';
import es from './es-ES.json';
import frCA from './fr-CA.json';
import fr from './fr-FR.json';
import id from './id-ID.json';
import it from './it-IT.json';
import ja from './ja-JP.json';
import ko from './ko-KR.json';
import nl from './nl-NL.json';
import ne from './ne-NP.json';
import pl from './pl-PL.json';
import ptBR from './pt-BR.json';
import pt from './pt-PT.json';
import ru from './ru-RU.json';
import ro from './ro-RO.json';
import sk from './sk-SK.json';
import so from './so-SO.json';
import uk from './uk-UA.json';
import tr from './tr-TR.json';
import zh from './zh-CN.json';
import en from './en-US.json';

interface Translations {
  privacy_policy_heading_1: string;
  privacy_policy_description_1: string;
  privacy_policy_heading_2: string;
  privacy_policy_description_2: string;
  privacy_policy_heading_3: string;
  privacy_policy_description_3: string;
  privacy_policy_description_3_point1: string;
  privacy_policy_description_3_point2: string;
  privacy_policy_description_3_point3: string;
  privacy_policy_description_3_point4: string;
  privacy_policy_description_3_point5: string;
  privacy_policy_description_3_point6: string;
  privacy_policy_description_3_biometrics: string;
  privacy_policy_heading_4: string;
  privacy_policy_description_4: string;
  privacy_policy_heading_5: string;
  privacy_policy_description_5: string;
  privacy_policy_description_5_point1: string;
  privacy_policy_description_5_point2: string;
  privacy_policy_description_5_point3: string;
  privacy_policy_description_5_point4: string;
  privacy_policy_description_5_point5: string;
  privacy_policy_description_5_point6: string;
  privacy_policy_description_5_point7: string;
  privacy_policy_description_5_point8: string;
  privacy_policy_description_5_point9: string;
  privacy_policy_heading_6: string;
  privacy_policy_description_6_para1: string;
  privacy_policy_description_6_para2: string;
  privacy_policy_description_6_para3: string;
  privacy_policy_description_6_para4: string;
  privacy_policy_heading_7: string;
  privacy_policy_description_7: string;
  privacy_policy_heading_8: string;
  privacy_policy_description_8: string;
  privacy_policy_addendum_heading_1: string;
  privacy_policy_addendum_description_1: string;
  privacy_policy_addendum_heading_2: string;
  privacy_policy_addendum_description_2: string;
  privacy_policy_addendum_heading_3: string;
  privacy_policy_addendum_description_3: string;
  privacy_policy_addendum_heading_4: string;
  privacy_policy_addendum_description_4: string;
  privacy_policy_addendum_heading_5: string;
  privacy_policy_addendum_description_5_para1: string;
  privacy_policy_addendum_description_5_para2: string;
  privacy_policy_addendum_description_5_point1: string;
  privacy_policy_addendum_description_5_point2: string;
  privacy_policy_addendum_heading_6: string;
  privacy_policy_addendum_description_6_para1: string;
  privacy_policy_addendum_description_6_para2: string;
}

const useLegalTranslations = (initialLanguage?: string) => {
  const [translations, setTranslations] = useState<Translations>(en);
  const [lanuage, setLanguage] = useState(initialLanguage);
  useEffect(() => {
    const loadTranslations = async () => {
      let translationData: Translations;
      switch (lanuage?.substring(0, 2)) {
        case 'ar':
          translationData = ar;
          break;
        case 'cs':
          translationData = cs;
          break;
        case 'de':
          translationData = de;
          break;
        case 'en':
          translationData = en;
          break;
        case 'es':
          translationData = es;
          break;
        case 'fr':
          translationData = lanuage === 'fr-FR' ? fr : frCA;
          break;
        case 'id':
          translationData = id;
          break;
        case 'it':
          translationData = it;
          break;
        case 'ja':
          translationData = ja;
          break;
        case 'ko':
          translationData = ko;
          break;
        case 'nl':
          translationData = nl;
          break;
        case 'pl':
          translationData = pl;
          break;
        case 'pt':
          translationData = lanuage === 'pt-PT' ? pt : ptBR;
          break;
        case 'ru':
          translationData = ru;
          break;
        case 'ne':
          translationData = ne;
          break;
        case 'sk':
          translationData = sk;
          break;
        case 'ro':
          translationData = ro;
          break;
        case 'so':
          translationData = so;
          break;
        case 'uk':
          translationData = uk;
          break;
        case 'tr':
          translationData = tr;
          break;
        case 'zh':
          translationData = zh;
          break;
        default:
          translationData = en;
      }
      setTranslations(translationData);
    };

    loadTranslations();
  }, [lanuage]);

  return { translations, setLanguage };
};

export default useLegalTranslations;
