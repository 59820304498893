import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Container, GridContainer, GridItem, View } from '@amzn/stencil-react-components/layout';
import defaultTheme from '@amzn/stencil-react-theme-default';
import SelectionContiner from 'src/components/common/SelectionContainer';
import Header from './Header';
import Footer from '../common/Footer';
import UserSelectionTileList from './UserSelectionTileList';
import TranslationKeys from 'src/constants/translation-keys';
import ScreenNames from 'src/constants/screen-names';
import useRecordScreenVisit from 'src/hooks/useRecordScreenVisit';

const UserTypeSelectionScreen = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        document.title = 'User Options - Amazon A to Z Login'
    }, []);
    
    useRecordScreenVisit(ScreenNames.USERTYPE_SELECTION);

    return (
        <StencilProvider
            theme={{
                ...defaultTheme,
                constants: {
                    ...defaultTheme.constants,
                    maxWidth: '768px'
                }
            }}>
            <Container className='deconsolidated-container'
                       data-testid='deconsolidated-container'>
                <GridContainer>
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Header backButtonOnClick={() => navigate("/")}/>
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={8} md={8} sm={4}>
                        <View flex={1} padding={{left: 50, right: 50}}>
                            <SelectionContiner
                                    screenName={ScreenNames.USERTYPE_SELECTION}
                                    selectionTitle={t(TranslationKeys.UI.USERTYPES.USERTYPES_SELECTION_TITLE)}>
                                <UserSelectionTileList />
                            </SelectionContiner>
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Footer />
                        </View>
                    </GridItem>
                </GridContainer>
            </Container>
        </StencilProvider>
    )
}

export default UserTypeSelectionScreen;