import React from 'react';
import { Container, Row, View } from '@amzn/stencil-react-components/layout';
import { Label } from '@amzn/stencil-react-components/text';
import FaqFlyout from './FaqFlyout';
import LanguageSelectFlyout from '../common/LanguageSelectFlyout';
import { recordEvent } from 'src/analytics/amplify'
import PrivacyFlyout from './PrivacyFlyout';
import { showPrivacyPolicyIngress } from 'src/constants/feature-gates';
import { UseCase } from 'src/components/common/UseCase';

type FooterProps = {
    screen: string
}

export default function Footer({ screen }: FooterProps) {

    function handleFlyoutClick(FlyoutType: string) {
        recordEvent({
            name: `${screen}.Footer.${FlyoutType}Flyout.Click`,
            immediate: true
        });
    }
    
    return (
      <Container>
        {/* Clean up after Alumni privacy policy release*/}
        {!showPrivacyPolicyIngress() ? (
          <>
            <hr className="footer-horizontal-rule" />
            <Row gridGap="S500" alignItems="center" justifyContent="center">
              <LanguageSelectFlyout useCase={UseCase.ALUMNI}/>
              <View alignItems="center">
                <Label textAlign="center">.</Label>
              </View>
              <View onClick={() => handleFlyoutClick('Faq')}>
                <FaqFlyout />
              </View>
            </Row>
          </>
        ) : (
          <>
            <hr className="footer-horizontal-rule" />
            <Row
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <LanguageSelectFlyout useCase={UseCase.ALUMNI}/>
              <View alignItems="center">
                <Label textAlign="center">.</Label>
              </View>
              <View onClick={() => handleFlyoutClick('Faq')}>
                <FaqFlyout />
              </View>
              <View alignItems="center">
                <Label textAlign="center">.</Label>
              </View>
              <View onClick={() => handleFlyoutClick('Privacy')}>
                <PrivacyFlyout />
              </View>
            </Row>
          </>
        )}
      </Container>
    );
}
